import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

export const fetchNotification = createAsyncThunk("notification/fetchNotification", async (_, thunkAPI) => {
  const response = await fetch(`${process.env.https://backend.scalex.cl/api/v1}/unread_notifications`)
  const { data } = await response.json()

  thunkAPI.dispatch(setItems(data))
  return data
})

const initialState = {
  loading: false,
  notifications: [],
  count: 0,
  error: "",
}

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    markReadAction: (state, { payload }) => {
      const newaaa = state.notifications.filter((el) => el.id !== payload)
      return {
        ...state,
        notifications: newaaa,
        count: newaaa.length,
      }
    },
    setItems: (state, { payload }) => {
      return {
        ...state,
        notifications: payload.items,
        count: payload.length,
        loading: false,
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchNotification.pending, (state) => {
      state.loading = true
      state.notifications = []
      state.count = 0
    })
    builder.addCase(fetchNotification.fulfilled, (state, { payload }) => {
      return {
        ...state,
        notifications: [...payload.items],
        count: payload.count,
        loading: false,
      }
    })
    builder.addCase(fetchNotification.rejected, (state, action) => {
      state.loading = false
      state.notifications = []
      state.count = 0
      state.error = action.error.message
    })
  },
})

// Action creators are generated for each case reducer function
export const { markReadAction, setItems } = notificationSlice.actions
export default notificationSlice.reducer
